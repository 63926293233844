import React from "react";
import InfoContactForm from "../../components/info-contact-us-form";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";

const subscriberWordpressWoes = process.env.GATSBY_SUBSCRIBER_LP_WORDPRESS_WOES_FORM_TAG;

const Main = ({location}) => (
  <section className="container-xl p-0">
    <div className="row m-auto">
      <div className="col-12 col-md-6 py-5 pr-md-5 py-md-6">
        <h3>729 Studios + Wordpress Support</h3>
        <h1>WordPress Woes? No worries — we've got your back</h1>
        <p>
          You have a business to run, and worrying about WordPress is the last
          thing want to do.
        </p>
        <p>Remember:</p>
        <div class="row">
          <div class="col-lg-6">
            <div className="py-3 d-flex">
              <svg
                className="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 42 46"
                height={44}
              >
                <g data-name="Group 246" transform="translate(-3 -1)">
                  <path
                    fill="none"
                    stroke="#92c954"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="2"
                    d="M44 22a20 20 0 1 0-40 0v24h40Z"
                    data-name="Path 694"
                  />
                  <path
                    fill="none"
                    stroke="#111"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="2"
                    d="M36 37c0-6.627-5.373-14-12-14s-12 7.373-12 14c0 0 7.334-4 12-4s12 4 12 4Z"
                    data-name="Path 695"
                  />
                  <circle
                    cx="2"
                    cy="2"
                    r="2"
                    fill="none"
                    stroke="#111"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="2"
                    data-name="Ellipse 30"
                    transform="translate(11 17)"
                  />
                  <circle
                    cx="2"
                    cy="2"
                    r="2"
                    fill="none"
                    stroke="#111"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="2"
                    data-name="Ellipse 31"
                    transform="translate(33 17)"
                  />
                  <circle
                    cx="2"
                    cy="2"
                    r="2"
                    fill="#111"
                    data-name="Ellipse 32"
                    transform="translate(11 17)"
                  />
                  <circle
                    cx="2"
                    cy="2"
                    r="2"
                    fill="#111"
                    data-name="Ellipse 33"
                    transform="translate(33 17)"
                  />
                </g>
              </svg>
              <p class="ml-3">Technology glitches will happen</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div className="py-3 d-flex">
              <svg
                className="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 46 41"
                height={44}
              >
                <g
                  fill="none"
                  stroke-miterlimit="10"
                  stroke-width="2"
                  data-name="Group 248"
                  transform="translate(-1 -3)"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    stroke="#111"
                    data-name="Ellipse 62"
                    transform="translate(4 4)"
                  />
                  <path
                    stroke="#111"
                    stroke-linecap="square"
                    d="M21 29c-7-3.583-7-10-13-10a6 6 0 0 0-6 6v18h12V31"
                    data-name="Path 696"
                  />
                  <path
                    stroke="#92c954"
                    stroke-linecap="square"
                    d="M27 29c7-3.583 7-10 13-10a6 6 0 0 1 6 6v18H34V31"
                    data-name="Path 697"
                  />
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    stroke="#92c954"
                    data-name="Ellipse 63"
                    transform="translate(34 4)"
                  />
                </g>
              </svg>
              <p class="ml-3">You don't have to WordPress alone</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div className="py-3 d-flex">
              <svg
                className="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 46.216 37.757"
                height={44}
              >
                <g
                  fill="none"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="2"
                >
                  <path
                    stroke="#111"
                    d="m28.867 7.068 6.349 18.689h5.5a4.5 4.5 0 0 1 4.5 4.5v3.5a3 3 0 0 1-3 3h-13L2.442 18.835a2.942 2.942 0 0 1-.683-4.092l9.144-12.8a1.867 1.867 0 0 1 2.6-.44l.013.009a1.9 1.9 0 0 1 .78 1.578 8.176 8.176 0 0 0 3.489 6.881 8.067 8.067 0 0 0 6.178 1.446"
                  />
                  <path stroke="#92c954" d="M1.216 36.757h14M1.216 28.757h5" />
                </g>
              </svg>
              <p class="ml-3">
                We've got a team of designers & developers ready to support
              </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div className="py-3 d-flex">
              <svg
                className="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 45.414 38"
                height={44}
              >
                <g
                  fill="none"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="2"
                  transform="translate(-1 -5)"
                >
                  <path stroke="#92c954" d="M16 18H2M16 30H2M32 42H2M32 6H2" />
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    stroke="#111"
                    transform="translate(24 16)"
                  />
                  <path stroke="#111" d="m45 37-7.344-7.344" />
                </g>
              </svg>
              <p class="ml-3">We find the best solutions for you</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 bg-primary py-6 px-lg-6 text-center">
        <h3 className="text-white">WordPress Problems got you down?</h3>
        <h3>Let 729 Solutions help solve your Wordpress problems.</h3>
        <div className="bg-white p-4 mt-3">
          <InfoContactForm
            location={location}
            tags={[subscriberWordpressWoes]}
            onSubmitSuccess={()=> navigate('/lp/729-solutions-studios-wordpress-support')}
            buttonText="SUMBIT" />
        </div>
      </div>
    </div>
  </section>
);

const Testimonial = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 align-self-center">
          <h2>What our clients are saying about our WordPress work</h2>
        </div>
        <div className="col-12 col-md-6">
          <Quote
            quote="729 allowed us the flexibility to self-implement, but were with us every step of the way. They answered questions, brainstormed, provided solutions and best practices for anything we needed. They are personable, caring, and reliable. I would recommend working with them, and feel confident everyone would feel the same value we did."
            name="KAYLA FISCHER"
            company="HireVue, Inc"
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => (
  <Layout>
    <Main location={location} />
    <Testimonial />
  </Layout>
);

export default Index;
